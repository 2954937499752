html {
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  color: #444;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  overflow: hidden; }

header,
nav,
footer {
  display: block; }

img {
  border-width: 0; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

a {
  padding: 8px; }
  a:hover {
    opacity: 0.8; }

h1 {
  font-size: 28px;
  margin: 30px 0;
  text-align: center;
  text-transform: uppercase; }

h2 {
  font-size: 24px;
  text-transform: uppercase; }

h3 {
  font-size: 20px;
  text-transform: uppercase; }

p {
  line-height: 1.5em;
  margin: 10px 0; }

sub,
sup {
  font-family: 'Courier New', monospace;
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

textarea {
  overflow: auto; }

button {
  overflow: visible; }

.button {
  border: none;
  border-radius: 4px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  height: 44px;
  margin: 10px auto 0;
  min-width: 180px;
  text-transform: uppercase; }
  .button:hover {
    opacity: 0.8; }
  .button[disabled] {
    cursor: not-allowed;
    opacity: 0.4; }

.sm-button {
  background: #eee;
  border: none;
  border-radius: 2px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  height: 24px;
  margin: 0;
  min-width: 80px;
  text-transform: uppercase; }
  .sm-button:hover {
    opacity: 0.8; }
  .sm-button[disabled] {
    cursor: not-allowed;
    opacity: 0.4; }

.msa-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 100vh;
  width: 100%; }
  .msa-container #skip {
    z-index: 10; }
    .msa-container #skip a {
      background: #fff;
      border: 1px solid #444;
      color: inherit;
      left: 12px;
      padding: 8px;
      position: absolute;
      text-decoration: none;
      transition: top 0.3s ease-out;
      top: -40px; }
      .msa-container #skip a:focus {
        transition: top 0.3s ease-in;
        top: 0; }
  .msa-container .loading {
    max-width: 860px;
    margin: 0 auto; }
  .msa-container .msa-header {
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
    z-index: 5; }
    .msa-container .msa-header .header {
      align-items: baseline;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      padding: 20px 30px;
      position: relative; }
      .msa-container .msa-header .header .header-logo a {
        display: inline-block;
        padding: 0; }
        .msa-container .msa-header .header .header-logo a img {
          height: 52px;
          width: auto; }
      .msa-container .msa-header .header .header-nav .nav {
        display: flex;
        text-transform: uppercase; }
        .msa-container .msa-header .header .header-nav .nav a {
          font-weight: 700;
          text-decoration: none; }
        .msa-container .msa-header .header .header-nav .nav span:not(.special) a {
          border-bottom: 5px solid #ffffff;
          color: inherit;
          padding: 15px 8px;
          margin: 0 4px;
          transition: border-bottom-color 0.3s linear, color 0.3s linear; }
          .msa-container .msa-header .header .header-nav .nav span:not(.special) a.selected {
            border-width: 5px;
            border-style: solid; }
        .msa-container .msa-header .header .header-nav .nav span.special a {
          border-radius: 18px;
          color: #fff;
          background: #666;
          padding: 8px 14px;
          margin: -8px 0 0 12px;
          transition: background-color 0.3s linear, color 0.3s linear; }
      .msa-container .msa-header .header .compact-nav .menu {
        background: #fff;
        border: none;
        cursor: pointer;
        position: absolute;
        right: 6px;
        top: 16px; }
        .msa-container .msa-header .header .compact-nav .menu .material-icons.md-36 {
          color: #444;
          font-size: 36px; }
      .msa-container .msa-header .header .compact-nav #compact-nav {
        background: #f5f5f5;
        border-top: 1px solid #ddd;
        box-shadow: -2px 2px 5px 0px rgba(0, 0, 0, 0.2);
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 68px;
        transition: max-height 0.2s ease-out, opacity 1s ease-out;
        width: 100%;
        z-index: 5; }
        .msa-container .msa-header .header .compact-nav #compact-nav .nav {
          display: flex;
          flex-direction: column;
          justify-content: center; }
          .msa-container .msa-header .header .compact-nav #compact-nav .nav span a {
            align-items: center;
            border-bottom: 1px solid #ddd;
            color: inherit;
            display: flex;
            font-weight: 700;
            justify-content: space-between;
            padding: 12px;
            text-transform: uppercase;
            text-decoration: none; }
            .msa-container .msa-header .header .compact-nav #compact-nav .nav span a:hover {
              color: #2b2b2b;
              background: gainsboro; }
        .msa-container .msa-header .header .compact-nav #compact-nav.is-active {
          max-height: 300px;
          opacity: 1;
          transition: max-height 0.2s ease-in; }
  .msa-container .msa-content {
    height: 100vh;
    overflow-x: scroll;
    position: relative; }
    .msa-container .msa-content .content {
      margin: 0 auto;
      min-height: calc(100% - 135px); }
      .msa-container .msa-content .content > div {
        padding: 30px; }
      .msa-container .msa-content .content .accessibility, .msa-container .msa-content .content .terms, .msa-container .msa-content .content .privacy-policy {
        max-width: 860px;
        margin: 0 auto; }
        .msa-container .msa-content .content .accessibility a, .msa-container .msa-content .content .terms a, .msa-container .msa-content .content .privacy-policy a {
          color: inherit;
          padding: 0; }
        .msa-container .msa-content .content .accessibility li, .msa-container .msa-content .content .terms li, .msa-container .msa-content .content .privacy-policy li {
          padding: 4px 0; }
      .msa-container .msa-content .content .not-found {
        max-width: 860px;
        margin: 0 auto; }
      .msa-container .msa-content .content .articles {
        display: flex;
        justify-content: space-between; }
        .msa-container .msa-content .content .articles h1 {
          font-size: 20px;
          margin-bottom: 0;
          text-transform: none;
          text-align: left; }
        .msa-container .msa-content .content .articles h3 {
          text-transform: none; }
        .msa-container .msa-content .content .articles h5 {
          margin-top: 0;
          text-transform: uppercase; }
        .msa-container .msa-content .content .articles .article-list {
          display: flex;
          flex-direction: column;
          flex: 1;
          padding: 0 40px;
          width: 100%; }
          .msa-container .msa-content .content .articles .article-list h1 {
            margin-top: 0px;
            margin-bottom: 10px;
            text-transform: uppercase; }
          .msa-container .msa-content .content .articles .article-list .article-item {
            border-bottom: 1px solid #999;
            padding: 1.67em 0; }
            .msa-container .msa-content .content .articles .article-list .article-item:first-child {
              padding-top: 0; }
            .msa-container .msa-content .content .articles .article-list .article-item:last-child {
              border-bottom: none; }
            .msa-container .msa-content .content .articles .article-list .article-item .image-link {
              height: 300px;
              padding: 0;
              width: 100%; }
            .msa-container .msa-content .content .articles .article-list .article-item .title-link {
              color: inherit;
              padding: 0;
              text-decoration: none; }
            .msa-container .msa-content .content .articles .article-list .article-item .read-more {
              margin-top: 12px; }
              .msa-container .msa-content .content .articles .article-list .article-item .read-more a {
                color: #545454;
                padding-left: 0;
                text-decoration: none;
                text-transform: uppercase; }
        .msa-container .msa-content .content .articles .cover-image {
          display: flex;
          align-items: center;
          height: 300px;
          overflow: hidden;
          width: 100%; }
          .msa-container .msa-content .content .articles .cover-image img {
            width: 100%; }
        .msa-container .msa-content .content .articles .article {
          display: flex;
          flex-direction: column;
          flex: 1;
          padding: 0 40px;
          width: 100%; }
          .msa-container .msa-content .content .articles .article a {
            color: #444;
            padding: 0; }
          .msa-container .msa-content .content .articles .article ol,
          .msa-container .msa-content .content .articles .article ul {
            margin: 8px 0; }
            .msa-container .msa-content .content .articles .article ol li,
            .msa-container .msa-content .content .articles .article ul li {
              padding: 8px 0; }
        .msa-container .msa-content .content .articles .left-side-nav {
          display: flex;
          flex-direction: column;
          width: 160px; }
        .msa-container .msa-content .content .articles .right-side-nav {
          display: flex;
          flex-direction: column;
          width: 160px; }
          .msa-container .msa-content .content .articles .right-side-nav .coaching-link {
            margin-top: 0; }
            .msa-container .msa-content .content .articles .right-side-nav .coaching-link h5 {
              margin-top: 0; }
        .msa-container .msa-content .content .articles .article-pagination {
          align-items: center;
          display: flex;
          justify-content: flex-end;
          margin: 30px 0; }
          .msa-container .msa-content .content .articles .article-pagination a {
            color: #999;
            padding: 0 4px;
            text-decoration: none; }
          .msa-container .msa-content .content .articles .article-pagination span {
            height: 22px; }
          .msa-container .msa-content .content .articles .article-pagination .disabled {
            color: #bbb;
            height: 28px;
            padding: 0 4px; }
        .msa-container .msa-content .content .articles.compact {
          flex-direction: column; }
          .msa-container .msa-content .content .articles.compact .table-of-contents {
            width: 100%; }
          .msa-container .msa-content .content .articles.compact .article-list {
            padding: 0;
            width: 100%; }
          .msa-container .msa-content .content .articles.compact .article {
            padding: 0; }
          .msa-container .msa-content .content .articles.compact .image-link {
            height: 120px; }
          .msa-container .msa-content .content .articles.compact .cover-image {
            height: 120px; }
      .msa-container .msa-content .content .calculator-container {
        margin-top: 20px;
        padding: 10px; }
      .msa-container .msa-content .content .calculators-list {
        margin-top: 40px;
        display: grid;
        grid-template-columns: auto auto;
        padding: 20px; }
      .msa-container .msa-content .content .calculator-card {
        display: flex;
        flex-flow: column wrap;
        background-color: #f5f6f8;
        border-radius: 3.5px;
        margin: 0px 20px 20px 0px;
        padding: 10px; }
      .msa-container .msa-content .content #spinner {
        display: block;
        margin: 160px auto; }
      .msa-container .msa-content .content .calculator-card > a {
        color: #0068c3;
        text-decoration: none;
        padding-left: 1; }
      .msa-container .msa-content .content .calculator-card > h4 {
        margin: 7px; }
      .msa-container .msa-content .content #calc-target-div {
        margin-top: 20px; }
      .msa-container .msa-content .content #calculator-back {
        text-decoration: none;
        color: #0068c3; }
      .msa-container .msa-content .content #calculator-back:visited {
        color: #0068c3; }
      .msa-container .msa-content .content #rendered-calc {
        display: flex;
        width: 90%;
        height: 80vh;
        margin: auto; }
      @media all and (max-width: 829px) {
        .msa-container .msa-content .content .calculator-container {
          padding: 0px; }
        .msa-container .msa-content .content .calculators-list {
          grid-template-columns: auto; }
        .msa-container .msa-content .content #rendered-calc {
          width: 100%; }
        .msa-container .msa-content .content .calculator-card {
          margin-right: 0px; } }
      .msa-container .msa-content .content .category-links a {
        color: #545454;
        display: block;
        font-size: 14px;
        padding: 6px 0;
        text-decoration: none; }
      .msa-container .msa-content .content h2 {
        line-height: 24px;
        margin: 0;
        font-size: 0.83em; }
      .msa-container .msa-content .content .compact .categories {
        width: 100%; }
      .msa-container .msa-content .content .compact .categories-menu h1 {
        line-height: 24px;
        margin-bottom: 0; }
        .msa-container .msa-content .content .compact .categories-menu h1 button {
          align-items: center;
          color: #444;
          cursor: pointer;
          display: flex;
          font-family: Montserrat, sans-serif;
          font-size: 1em;
          font-weight: bold;
          padding: 8px 8px 8px 0;
          text-transform: uppercase; }
          .msa-container .msa-content .content .compact .categories-menu h1 button i {
            margin-left: 4px; }
      .msa-container .msa-content .content .compact .categories-menu .category-menu {
        background: #fff;
        box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
        position: absolute;
        left: 15px;
        padding: 10px;
        width: 240px; }
      .msa-container .msa-content .content .checkup {
        margin: 100px auto 0;
        max-width: 860px;
        text-align: center; }
        .msa-container .msa-content .content .checkup h3 {
          padding-bottom: 20px; }
        .msa-container .msa-content .content .checkup .button {
          margin-top: 26px; }
        .msa-container .msa-content .content .checkup .overview {
          margin: auto;
          max-width: 540px; }
        .msa-container .msa-content .content .checkup .questions {
          margin: auto;
          max-width: 540px; }
          .msa-container .msa-content .content .checkup .questions h4 {
            margin-bottom: 58px; }
          .msa-container .msa-content .content .checkup .questions .question {
            width: 100%; }
            .msa-container .msa-content .content .checkup .questions .question h1 {
              font-size: 0.95em;
              text-transform: capitalize; }
            .msa-container .msa-content .content .checkup .questions .question .scores {
              border: 1px solid #444;
              border-radius: 4px;
              display: flex;
              justify-content: space-around;
              list-style: none;
              padding: 0; }
              .msa-container .msa-content .content .checkup .questions .question .scores button {
                background-color: #fff;
                border: none;
                border-right: 1px solid #444;
                cursor: pointer;
                font-family: inherit;
                font-size: inherit;
                padding: 14px 0;
                width: 100%; }
                .msa-container .msa-content .content .checkup .questions .question .scores button:hover {
                  background-color: #eee; }
                .msa-container .msa-content .content .checkup .questions .question .scores button:first-child {
                  border-radius: 4px 0 0 4px; }
                .msa-container .msa-content .content .checkup .questions .question .scores button:last-child {
                  border: none;
                  border-radius: 0 4px 4px 0; }
                .msa-container .msa-content .content .checkup .questions .question .scores button.selected {
                  font-weight: 700;
                  z-index: 5; }
            .msa-container .msa-content .content .checkup .questions .question .score-labels {
              display: flex;
              font-size: 12px;
              font-weight: 700;
              justify-content: space-between;
              margin-top: 12px; }
        .msa-container .msa-content .content .checkup .action {
          margin-top: 30px; }
        .msa-container .msa-content .content .checkup .results {
          display: flex; }
          .msa-container .msa-content .content .checkup .results .chart {
            flex: 1 0 300px;
            margin: auto; }
            .msa-container .msa-content .content .checkup .results .chart .progress .progress-value {
              animation: progress 1s ease-in; }

@keyframes progress {
  from {
    stroke-dashoffset: 0; } }
          .msa-container .msa-content .content .checkup .results .explanation {
            margin-left: 40px; }
            .msa-container .msa-content .content .checkup .results .explanation h1 {
              font-size: 22px;
              margin: 0;
              text-align: left; }
            .msa-container .msa-content .content .checkup .results .explanation p {
              text-align: left; }
            .msa-container .msa-content .content .checkup .results .explanation a {
              color: #444;
              padding: 0; }
      .msa-container .msa-content .content .coaching {
        margin: auto;
        max-width: 614px;
        text-align: center; }
        .msa-container .msa-content .content .coaching .banner {
          align-items: center;
          background-image: url(/img/coaching/Coaching-Banner.png);
          background-position: center;
          background-size: cover;
          display: flex;
          flex-direction: column;
          height: 320px;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%; }
          .msa-container .msa-content .content .coaching .banner p {
            color: #ffffff;
            font-weight: 700;
            line-height: 2em;
            margin: auto;
            max-width: 780px;
            padding: 0 20px; }
        .msa-container .msa-content .content .coaching .container {
          margin-top: 340px; }
          .msa-container .msa-content .content .coaching .container h2 {
            font-size: 24px;
            text-align: center;
            text-transform: uppercase; }
          .msa-container .msa-content .content .coaching .container p {
            line-height: 2em;
            margin: 40px 0 50px; }
          .msa-container .msa-content .content .coaching .container a {
            color: #fff;
            display: block;
            text-decoration: none;
            width: 100%; }
          .msa-container .msa-content .content .coaching .container button {
            margin: 6px 10px;
            width: 220px; }
      .msa-container .msa-content .content .coaching-link {
        margin-top: 30px;
        text-align: center; }
        .msa-container .msa-content .content .coaching-link h2 {
          margin: 0px;
          font-size: 0.83em; }
        .msa-container .msa-content .content .coaching-link button {
          font-size: 16px;
          min-width: 100px;
          width: 100%; }
      .msa-container .msa-content .content .compact .coaching-link {
        height: 150px;
        position: relative; }
        .msa-container .msa-content .content .compact .coaching-link .coaching-link-container {
          align-items: center;
          background-image: url(/img/coaching/Coaching-Banner.png);
          background-position: center;
          background-size: cover;
          color: #fff;
          display: flex;
          flex-direction: column;
          height: 150px;
          justify-content: center;
          left: -15px;
          position: absolute;
          top: 0;
          width: calc(100% + 30px); }
          .msa-container .msa-content .content .compact .coaching-link .coaching-link-container .coaching-link-content {
            font-size: 18px;
            text-align: center;
            width: 160px; }
      .msa-container .msa-content .content .contact {
        margin: 0 auto;
        width: 400px; }
        .msa-container .msa-content .content .contact h2 {
          text-align: center; }
        .msa-container .msa-content .content .contact form {
          display: flex;
          flex-direction: column; }
          .msa-container .msa-content .content .contact form input,
          .msa-container .msa-content .content .contact form textarea {
            border-width: 2px;
            border-style: solid;
            border-radius: 4px;
            color: #444;
            font-size: 16px;
            margin-bottom: 14px;
            padding: 8px;
            width: 100%; }
            .msa-container .msa-content .content .contact form input::placeholder,
            .msa-container .msa-content .content .contact form textarea::placeholder {
              color: #999; }
          .msa-container .msa-content .content .contact form label {
            color: #999;
            font-size: 14px;
            text-transform: uppercase; }
          .msa-container .msa-content .content .contact form .fullname {
            display: flex;
            flex-direction: row;
            justify-content: space-between; }
            .msa-container .msa-content .content .contact form .fullname div {
              width: 48%; }
      .msa-container .msa-content .content .home p {
        max-width: 760px;
        text-align: center;
        margin: 10px auto; }
      .msa-container .msa-content .content .home .feature-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 60px 0 0; }
        .msa-container .msa-content .content .home .feature-container .feature {
          margin: 0 auto;
          max-width: 550px;
          width: calc(50% - 20px); }
          .msa-container .msa-content .content .home .feature-container .feature h3 {
            text-align: center; }
          .msa-container .msa-content .content .home .feature-container .feature > div {
            background: #777;
            overflow: hidden;
            position: relative; }
            .msa-container .msa-content .content .home .feature-container .feature > div a {
              padding: 0; }
            .msa-container .msa-content .content .home .feature-container .feature > div img {
              display: block;
              transition: transform 0.3s;
              width: 100%; }
            .msa-container .msa-content .content .home .feature-container .feature > div:hover img {
              transform: scale(1.08);
              transition: transform 0.3s; }
            .msa-container .msa-content .content .home .feature-container .feature > div .title {
              color: #fff;
              position: absolute;
              left: 50%;
              text-transform: uppercase;
              top: 40%;
              transform: translate(-50%, -60%); }
          .msa-container .msa-content .content .home .feature-container .feature p {
            margin: 16px auto 60px;
            width: 82%; }
      .msa-container .msa-content .content .videos {
        display: flex;
        justify-content: space-between; }
        .msa-container .msa-content .content .videos h1 {
          font-size: 20px;
          margin: 0 0 10px;
          text-align: left; }
        .msa-container .msa-content .content .videos h2 {
          margin-bottom: 14px;
          text-transform: uppercase;
          font-size: 0.83em; }
        .msa-container .msa-content .content .videos h3 {
          font-size: 20px;
          text-transform: none; }
        .msa-container .msa-content .content .videos h5 {
          margin-top: 0;
          text-transform: uppercase; }
        .msa-container .msa-content .content .videos .table-of-contents {
          width: 200px; }
          .msa-container .msa-content .content .videos .table-of-contents .topic {
            align-items: center;
            display: flex;
            font-size: 14px;
            justify-content: space-between;
            min-height: 40px;
            line-height: 1em;
            margin-top: 10px;
            padding: 4px 10px; }
            .msa-container .msa-content .content .videos .table-of-contents .topic:first-of-type {
              margin-top: 0; }
          .msa-container .msa-content .content .videos .table-of-contents .topic-heading {
            align-items: center;
            background: #777;
            color: #fff;
            cursor: pointer;
            display: flex;
            font-size: 14px;
            justify-content: space-between;
            min-height: 40px;
            line-height: 1em;
            margin-top: 30px;
            padding: 4px 10px; }
          .msa-container .msa-content .content .videos .table-of-contents .topic-videos a {
            color: #545454;
            display: block;
            font-size: 14px;
            padding: 6px 8px;
            text-decoration: none; }
        .msa-container .msa-content .content .videos .video-container {
          display: flex;
          flex-direction: column;
          flex: 1;
          padding: 0 40px;
          width: 100%; }
          .msa-container .msa-content .content .videos .video-container .video .player {
            position: relative;
            padding-bottom: 56.25%;
            height: 0;
            overflow: hidden;
            max-width: 100%; }
            .msa-container .msa-content .content .videos .video-container .video .player iframe,
            .msa-container .msa-content .content .videos .video-container .video .player object,
            .msa-container .msa-content .content .videos .video-container .video .player embed {
              height: 100%;
              left: 0;
              position: absolute;
              top: 0;
              width: 100%; }
          .msa-container .msa-content .content .videos .video-container .video .videoTranscript a:link,
          .msa-container .msa-content .content .videos .video-container .video .videoTranscript a:visited {
            color: #545454;
            display: inline-block;
            text-decoration: none;
            border: 1px solid #545454;
            margin-top: 20px; }
          .msa-container .msa-content .content .videos .video-container .up-next {
            margin-top: 30px; }
            .msa-container .msa-content .content .videos .video-container .up-next .up-next-videos {
              display: flex; }
              .msa-container .msa-content .content .videos .video-container .up-next .up-next-videos a {
                padding: 0;
                margin: 0 4px; }
              .msa-container .msa-content .content .videos .video-container .up-next .up-next-videos a:first-of-type {
                margin-left: 0; }
              .msa-container .msa-content .content .videos .video-container .up-next .up-next-videos a:last-of-type {
                margin-right: 0; }
              .msa-container .msa-content .content .videos .video-container .up-next .up-next-videos img {
                width: 100%; }
        .msa-container .msa-content .content .videos .right-side-nav {
          display: flex;
          flex-direction: column;
          width: 160px; }
        .msa-container .msa-content .content .videos.compact {
          flex-direction: column; }
          .msa-container .msa-content .content .videos.compact .table-of-contents {
            width: 100%; }
          .msa-container .msa-content .content .videos.compact .video-container {
            padding: 0;
            width: 100%; }
  .msa-container .msa-footer {
    box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.2);
    height: 135px; }
    .msa-container .msa-footer .footer {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-around;
      margin: 0 auto;
      padding: 30px; }
      .msa-container .msa-footer .footer .links {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        height: 70px;
        justify-content: space-around; }
        .msa-container .msa-footer .footer .links span {
          color: #444; }
          .msa-container .msa-footer .footer .links span a {
            color: inherit;
            font-weight: 400;
            padding: 0 24px;
            text-decoration: none;
            text-transform: uppercase; }
      .msa-container .msa-footer .footer .copyright {
        font-size: 0.9rem; }

@media all and (min-width: 1200px) {
  .msa-container .msa-header .header,
  .msa-container .msa-content .content,
  .msa-container .msa-footer .footer {
    width: 1200px; } }

@media all and (max-width: 829px) {
  .msa-container .msa-header .header,
  .msa-container .msa-footer .footer {
    padding: 10px 15px;
    width: 100%; }
  .msa-container .msa-content {
    -webkit-overflow-scrolling: touch; }
    .msa-container .msa-content .content > div {
      padding: 10px 15px 40px 15px; }
      .msa-container .msa-content .content > div.videos, .msa-container .msa-content .content > div.articles {
        padding-bottom: 0; }
    .msa-container .msa-content .content .checkup .results {
      flex-direction: column; }
      .msa-container .msa-content .content .checkup .results .chart {
        margin: 30px auto; }
      .msa-container .msa-content .content .checkup .results .explanation {
        margin: 0 auto;
        width: 80%; }
        .msa-container .msa-content .content .checkup .results .explanation h3 {
          text-align: center; }
  .msa-container .msa-header .header {
    align-items: center; }
    .msa-container .msa-header .header .header-logo a img {
      height: 44px; } }

@media all and (max-width: 560px) {
  .msa-container .msa-content .content .home .feature-container {
    justify-content: center; }
    .msa-container .msa-content .content .home .feature-container .feature {
      width: 100%; }
  .msa-container .msa-content .content .checkup {
    margin-top: 10px; }
    .msa-container .msa-content .content .checkup h3 {
      padding-bottom: 10px; } }

@media all and (max-width: 499px) {
  .button {
    width: 100%; }
  .msa-container .msa-content .content .checkup .results .explanation {
    width: 90%; }
  .msa-container .msa-content .content .coaching .banner p {
    font-size: 14px; }
  .msa-container .msa-content .content .contact {
    width: 100%; }
    .msa-container .msa-content .content .contact form .fullname {
      display: flex;
      flex-direction: column;
      width: 100%; }
      .msa-container .msa-content .content .contact form .fullname div {
        width: 100%; }
  .msa-container .msa-footer .footer .links {
    width: 100%;
    flex-direction: column; } }
